import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FilterBar, FilterGroup, Popover } from 'ui-v2';

import {
  activeFiltersVar,
  useFilterVisibility,
  filterVisibilityVar,
  useActiveFilters,
  FILTERS,
  setStoredFilters,
} from '@/contexts';
import { FilterField } from '@/generated/graphql';

import { useFilterCounts } from './useFilterCounts';

export const FilterPanel = () => {
  const { t: translate } = useTranslation();
  const t = (key: string) => {
    const translation = translate(key);
    return translation === key ? null : translation;
  };

  const isOpen = useFilterVisibility();
  const activeFilters = useActiveFilters();
  const { indicators } = useFilterCounts();

  const [value, setValue] = useState(activeFilters);

  const filterList = Object.entries(activeFilters).reduce<{ label: string; value: string }[]>(
    (acc, [field, filters]) => {
      const filterGroup = FILTERS.find((x) => x.field === field);
      if (!filterGroup) return acc;

      for (const filter of filters) {
        const filterDefinition = filterGroup.filters.find((x) => x.value === filter);
        if (!filterDefinition) continue;

        const labelKey = filterDefinition.value.toLowerCase().replace(/[^a-z0-9]/g, '');
        const label =
          t(`${filterGroup.localePrefix}_optionLabel_${labelKey}`) ?? filterDefinition.value;
        acc.push({ label, value: filterDefinition.value });
      }

      return acc;
    },
    []
  );

  const handleFilterToggle = (field: FilterField, filter: string) => {
    setValue((prev) => {
      const currentFilters = prev[field] ?? [];
      return {
        ...prev,
        [field]: currentFilters.includes(filter)
          ? currentFilters.filter((x: string) => x !== filter)
          : [...currentFilters, filter],
      };
    });
  };

  const handleRemoveFilter = (toRemove: string) => {
    const filterGroup = FILTERS.find((x) => x.filters.some((y) => y.value === toRemove));
    if (!filterGroup) return;

    const currentFilters = activeFiltersVar();
    activeFiltersVar({
      ...currentFilters,
      [filterGroup.field]: currentFilters[filterGroup.field].filter((x) => x !== toRemove),
    });
  };

  const handleClearAll = () => {
    activeFiltersVar({});
  };

  const handleSubmit = () => {
    activeFiltersVar(value);
    filterVisibilityVar(false);
  };

  useEffect(() => {
    setValue(activeFilters);
    setStoredFilters(activeFilters);
  }, [activeFilters]);

  return (
    <FilterBar>
      <Popover
        open={isOpen}
        onOpenChange={() => {
          filterVisibilityVar(!isOpen);
          setValue(activeFilters);
        }}
      >
        <div className="flex items-center gap-3">
          <Popover.Trigger>
            <FilterBar.Action isActive={isOpen} />
          </Popover.Trigger>
          <FilterBar.Filters activeFilters={filterList} onRemove={handleRemoveFilter} />
        </div>
        <Popover.Content sideOffset={12} className="p-0 sm:p-0">
          <div className="max-h-[50vh] space-y-8 overflow-y-auto p-4 sm:px-9 sm:py-6">
            {FILTERS.map(({ field, localePrefix, indicatorName, filters }) => {
              const title = t(`${localePrefix}_filterLabel`);
              // @ts-expect-error TS can't confirm that field is a key of indicators, but that's okay
              const indicatorValues = indicators?.[indicatorName]?.items ?? [];

              return (
                <FilterGroup
                  key={title}
                  filters={value[field] ?? []}
                  toggleFilter={(x) => handleFilterToggle(field, x)}
                >
                  <FilterGroup.Title>{title}</FilterGroup.Title>
                  <FilterGroup.Filters>
                    {filters.map((filter) => {
                      const labelKey = filter.value.toLowerCase().replace(/[^a-z0-9]/g, '');
                      const label = t(`${localePrefix}_optionLabel_${labelKey}`) ?? filter.value;
                      const indicatorItem = indicatorValues.find(
                        (x: { label: string; count: number }) => x.label === filter.value
                      );
                      const isDisabled =
                        filter.isDisabled?.(indicatorValues) ?? (indicatorItem?.count ?? 0) < 5;

                      return (
                        <FilterGroup.Filter key={label} value={filter.value} disabled={isDisabled}>
                          {label}
                        </FilterGroup.Filter>
                      );
                    })}
                  </FilterGroup.Filters>
                </FilterGroup>
              );
            })}
          </div>

          <div className="sticky bottom-0 flex justify-end gap-5 border-t bg-white p-4 sm:px-9 sm:py-6">
            <Button variant="outline" color="barbie" onClick={handleClearAll}>
              Clear All
            </Button>
            <Button variant="solid" color="midnight" onClick={handleSubmit}>
              Apply
            </Button>
          </div>
        </Popover.Content>
      </Popover>
    </FilterBar>
  );
};
