import React from 'react';
import Markdown, { type Components } from 'react-markdown';

import { cn } from 'utils';
import { BorderBox } from '../BorderBox';

import style from './MarkdownContent.module.css';

const components: Components = {
  pre: ({ children }) => (
    <div className="text-barbie bg-coral-50 mx-auto w-fit rounded px-4 py-3">{children}</div>
  ),
  code: ({ children }) => <>{children}</>,
};

export function MarkdownContent({ content }: { content: string }) {
  return (
    <main className="space-y-4">
      {content.split('---\n').map((block, i) => (
        <BorderBox key={i} className={cn('p-6 sm:p-6', style.content)}>
          <Markdown components={components}>{block.replace('---\n', '')}</Markdown>
        </BorderBox>
      ))}
    </main>
  );
}
