import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { cn } from 'utils';
import { LabelText } from '../LabelText';
import { Tooltip } from '../Tooltip';

export interface HorizontalBarProps {
  value: number | null;
  label: string;
  tooltipLabel?: string;
  color: string;
  min: number;
  max: number;
  displayValue: string;
  icon?: ReactNode;
  showInlineDisplayValue?: boolean;
  isDashed?: boolean;
}

export const HorizontalBar: FC<PropsWithChildren & HorizontalBarProps> = ({
  children,
  label,
  tooltipLabel,
  value,
  icon,
  displayValue,
  color,
  min,
  max,
  showInlineDisplayValue = true,
  isDashed = false,
}) => {
  const [animatedWidth, setAnimatedWidth] = useState(0);

  const isValueMissing = value === null || value === undefined;
  const barWidth = isValueMissing ? 0 : Math.max(((value - min) / (max - min)) * 100, 1);

  useEffect(() => {
    if (!isValueMissing) {
      setTimeout(() => {
        setAnimatedWidth(barWidth);
      }, 0);
    }
  }, [barWidth, isValueMissing]);

  return (
    <div className="space-y-1">
      <div className="lg:hidden">{icon}</div>
      <div className="lg:hidden">{children}</div>
      <LabelText className={cn({ 'lg:ml-9': icon })}>{label}</LabelText>
      {isValueMissing ? (
        <p className="text-red">Data not available</p>
      ) : (
        <div className="flex items-center">
          <div className="flex flex-grow items-center gap-1">
            <div className="hidden lg:block">{icon}</div>
            <Tooltip variant="tooltip">
              <Tooltip.Trigger>
                <div
                  className={cn(
                    'relative z-0 flex h-[1.125rem] items-center justify-end rounded text-sm transition-all duration-500 ease-in sm:h-6 sm:text-base',
                    { 'striped-element': isDashed }
                  )}
                  style={
                    {
                      width: value === 0 ? '4px' : `${animatedWidth}%`,
                      backgroundColor: color,
                      '--stripe-color': color,
                    } as React.CSSProperties
                  }
                >
                  {value > max * 0.85 && showInlineDisplayValue && (
                    <p className="z-10 mr-2 text-sm font-medium text-black opacity-60">
                      {displayValue}
                    </p>
                  )}
                </div>
              </Tooltip.Trigger>
              {!showInlineDisplayValue && (
                <Tooltip.Content>
                  <Tooltip.ChartValue
                    label={tooltipLabel ?? label}
                    value={displayValue}
                    color={color}
                  />
                  <Tooltip.Arrow />
                </Tooltip.Content>
              )}
            </Tooltip>
            {value <= max * 0.85 && showInlineDisplayValue && (
              <p className={'text-sm font-medium text-black opacity-60'}>{displayValue}</p>
            )}
          </div>
          <div className="hidden lg:block">{children}</div>
        </div>
      )}
    </div>
  );
};
