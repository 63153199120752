import { faInvestors } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BigStat, ChartCard, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_SampleSizeFragment = gql(/* GraphQL */ `
  fragment Index_SampleSizeFragment on ForestryIndicators {
    numberOfOrgs
    numberOfInvestees
    numberOfObservations
  }
`);

type SampleSizeProps = {
  className?: string;
  indicators: FragmentType<typeof Index_SampleSizeFragment>;
};

export const SampleSize: React.FC<SampleSizeProps> = ({ indicators, className }) => {
  const { numberOfOrgs, numberOfInvestees, numberOfObservations } = useFragment(
    Index_SampleSizeFragment,
    indicators
  );

  const { title, chartTooltip, t } = useChartTranslations('sampleSize');
  const investorsTitle = t('investors_title') ?? '';
  const investeesTitle = t('investees_title') ?? '';
  const observationsTitle = t('observations_title') ?? '';

  return (
    <ChartCard className={className}>
      <ChartCard.Body className="space-y-3">
        <ChartCard.Title className="text-barbie">
          {title}
          {chartTooltip && <InfoTooltip>{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        <div className="flex items-center justify-between">
          <BigStat className="font-light" label={investorsTitle}>
            {numberOfOrgs}
          </BigStat>
          <BigStat className="font-light" label={investeesTitle}>
            {numberOfInvestees}
          </BigStat>
          <BigStat className="font-light" label={observationsTitle}>
            {numberOfObservations}
          </BigStat>
          <FontAwesomeIcon
            icon={faInvestors}
            size="2xl"
            className="text-barbie size-6 self-start lg:size-8"
          />
        </div>
      </ChartCard.Body>
    </ChartCard>
  );
};
