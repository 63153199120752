import { faSdg15 } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard, InfoTooltip, LabelText, VerticalBar } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';

import { useChartData } from '../-hooks/useChartData';
import { colors } from 'ui-v2/theme';

export const Sdg_LandRestoredFragment = gql(/* GraphQL */ `
  fragment Sdg_LandRestoredFragment on ForestryIndicators {
    pctLandRestored {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type LandRestoredProps = {
  className?: string;
  viewerIndicators?: FragmentType<typeof Sdg_LandRestoredFragment>;
  indicators: FragmentType<typeof Sdg_LandRestoredFragment>;
};

export function LandRestored({ indicators, viewerIndicators, className }: LandRestoredProps) {
  const { t } = useTranslation();

  const benchmark = useFragment(Sdg_LandRestoredFragment, indicators);
  const viewer = useFragment(Sdg_LandRestoredFragment, viewerIndicators);

  const benchmarkData = benchmark.pctLandRestored;
  const viewerData = viewer?.pctLandRestored;

  const { groups, data, yAxisProps } = useChartData({
    viewerData,
    benchmarkData,
    referenceLineValues: [0, 13.9],
  });

  const titleTooltip = t('landRestored_titleTooltip_sdg');

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faSdg15} size="2x" className="text-sdg-15" />
          <LabelText>{t('sdgsTargeted_optionLabel_15')}</LabelText>
        </div>
        <ChartCard.Title>
          {t('landRestored_title')}
          {titleTooltip && <InfoTooltip className="ml-1">{titleTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={benchmarkData.isDataSuppressed}>
        <VerticalBar groups={groups} data={data}>
          <VerticalBar.Chart
            yAxis={yAxisProps}
            referenceLines={[
              { value: 0, color: colors.gray[500] },
              { value: 13.9028459, color: colors.barbie, label: 'TARGET (13.9%)' },
            ]}
          />
          <VerticalBar.Legend className="flex items-center justify-center gap-4" />
        </VerticalBar>
      </ChartCard.Body>
    </ChartCard>
  );
}
