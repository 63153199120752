import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { InvalidFilters } from '@/components/InvalidFilters';
import { useIsValidFilters } from '@/hooks/useIsValidFilters';

import { AssetClass } from './-components/AssetClass';
import { AssetsUnderManagement } from './-components/AssetsUnderManagement';
import { CertificationsPresent } from './-components/CertificationsPresent';
import { Ecoregion } from './-components/Ecoregion';
import { FinancialReturns } from './-components/FinancialReturns';
import { GeographicFocus } from './-components/GeographicFocus';
import { ImpactStrategy } from './-components/ImpactStrategy';
import { InvestmentsRepresented } from './-components/InvestmentsRepresented';
import { InvestorsRepresented } from './-components/InvestorsRepresented';
import { CountryIncomeGroup } from './-components/CountryIncomeGroup';
import { PresenceOfCertifications } from './-components/PresenceOfCertifications';
import { SampleSize } from './-components/SampleSize';
import { StageOfBusiness } from './-components/StageOfBusiness';
import { StrategicGoals } from './-components/StrategicGoals';
import { StrategicDevelopmentGoals } from './-components/StrategicDevelopmentGoals';
import { RegionsAndCountries } from './-components/RegionsAndCountries';
import { useIndexData } from './-hooks/useIndexData';

export const Route = createFileRoute('/')({
  component: Overview,
});

function Overview() {
  const { loading, isValid } = useIsValidFilters();
  const { indicators } = useIndexData({ skip: !isValid || loading });

  if (loading) return null;
  if (!isValid) return <InvalidFilters />;
  if (!indicators) return null;

  return (
    <div className="space-y-6 lg:space-y-5">
      {/* First block */}
      <div className="grid grid-cols-2 gap-6 lg:grid-cols-10 lg:grid-rows-2 lg:gap-5">
        <SampleSize indicators={indicators} className="col-span-2 lg:col-span-4 lg:row-start-1" />
        <AssetsUnderManagement
          indicators={indicators}
          className="col-span-2 lg:col-span-4 lg:row-start-2"
        />
        <FinancialReturns
          className="col-span-2 lg:col-span-3 lg:row-span-2"
          indicators={indicators}
        />
        <AssetClass className="col-span-2 lg:col-span-3 lg:row-span-2" indicators={indicators} />
      </div>

      {/* Second block */}
      <div className="grid gap-6 lg:grid-cols-10 lg:grid-rows-2 lg:gap-5">
        <CountryIncomeGroup indicators={indicators} className="lg:col-span-4" />
        <GeographicFocus className="lg:col-span-4" indicators={indicators} />
        <RegionsAndCountries
          className="lg:col-span-6 lg:col-start-5 lg:row-span-2 lg:row-start-1"
          indicators={indicators}
        />
      </div>

      {/* Third block */}
      <div className="grid items-stretch gap-6 lg:grid-cols-10 lg:gap-5">
        <div className="flex-col gap-6 lg:col-span-6 lg:flex lg:gap-5 [&>*]:grow">
          <StrategicGoals indicators={indicators} />
          <StrategicDevelopmentGoals indicators={indicators} className="hidden lg:flex" />
        </div>
        <div className="flex flex-col gap-6 lg:col-span-4 lg:col-start-7 lg:gap-5 [&>*]:grow">
          <ImpactStrategy indicators={indicators} />
          <StageOfBusiness indicators={indicators} />
          <Ecoregion indicators={indicators} />
          <PresenceOfCertifications indicators={indicators} />
          <CertificationsPresent indicators={indicators} />
        </div>
        <StrategicDevelopmentGoals indicators={indicators} className="lg:hidden" />
      </div>
    </div>
  );
}
