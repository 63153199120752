import { orderBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { ChartCard, generateChartColors, InfoTooltip, PieChart } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_ImpactStrategyFragment = gql(/* GraphQL */ `
  fragment Index_ImpactStrategyFragment on ForestryIndicators {
    impactStrategy {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type ImpactStrategyProps = {
  className?: string;
  indicators: FragmentType<typeof Index_ImpactStrategyFragment>;
};

export function ImpactStrategy({ indicators, className }: ImpactStrategyProps) {
  const { impactStrategy } = useFragment(Index_ImpactStrategyFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip, getOptionDisplayValue } =
    useChartTranslations('investeePrimaryStrategy');

  const pieData = useMemo(() => {
    const colors = generateChartColors(impactStrategy.items.length);
    const sorted = orderBy(
      impactStrategy.items
        .filter((item) => !!item)
        .map((item) => ({
          label: getOptionLabel(item?.label),
          tooltipLabel: getOptionTooltip(item?.label),
          value: item?.pct,
          displayValue: getOptionDisplayValue(item.count, {
            pct: item.pct.toLocaleString('en', { maximumFractionDigits: 1 }),
          }),
        })),
      ['value'],
      ['desc']
    );
    return sorted.map((x, i) => ({ ...x, color: colors[i] }));
  }, [impactStrategy.items]);

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={impactStrategy.isDataSuppressed}>
        <PieChart data={pieData} showLegend chartPosition="left" />
      </ChartCard.Body>
    </ChartCard>
  );
}
