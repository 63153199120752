import { FilterCond, FilterField, FilterOp } from '@/generated/graphql';
import { storage } from '@/utils';

import { FILTERS } from './filters';
import type { FilterDefinition, Filters } from './types';

const LOCAL_STORAGE_FILTERS_KEY = 'activeFilters';

export const getStoredFilters = () => {
  const storedFilters = storage.local.get(LOCAL_STORAGE_FILTERS_KEY, {});

  const filters = {} as Filters;
  for (const filter of Object.values(FilterField)) {
    filters[filter] = storedFilters?.[filter] || [];
  }

  return filters;
};

export const setStoredFilters = (value: Filters) => {
  storage.local.set(LOCAL_STORAGE_FILTERS_KEY, value);
};

export const deriveFilterConditions = (filters: Filters): FilterCond[] => {
  return Object.entries(filters).reduce<FilterCond[]>((acc, [field, values]) => {
    const filterGroup = FILTERS.find((x) => x.field === field);
    if (!filterGroup || values.length === 0) return acc;

    const filterDefinitions = values
      .map((x) => filterGroup.filters.find((y) => y.value === x))
      .filter((x): x is FilterDefinition => !!x);

    if (filterDefinitions.every((x) => !x.condition)) {
      acc.push({
        op: FilterOp.In,
        field: filterGroup.field,
        value: values,
      });
      return acc;
    }

    acc.push(
      ...filterDefinitions.map((x) => {
        if (x.condition) return x.condition;
        return {
          group: filterGroup.field,
          op: FilterOp.Eq,
          field: filterGroup.field,
          value: x.value,
        };
      })
    );
    return acc;
  }, []);
};
