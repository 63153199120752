import { FilterField, FilterOp } from '@/generated/graphql';

import type { FilterGroup } from './types';

export const FILTERS: FilterGroup[] = [
  {
    field: FilterField.AssetClass,
    localePrefix: 'assetClass',
    indicatorName: 'assetClass',
    filters: [
      { value: 'Deposits & cash equivalents' },
      { value: 'Private debt' },
      { value: 'Public debt' },
      { value: 'Private equity' },
      { value: 'Public equity' },
      { value: 'Real assets' },
      { value: 'Other (please specify)' },
    ],
  },
  {
    field: FilterField.YearsSinceInvestment,
    localePrefix: 'yearsSinceInvestment',
    indicatorName: 'yearsSinceInvestment',
    filters: [
      {
        value: 'sm',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.In,
          field: FilterField.YearsSinceInvestment,
          value: [1, 2, 3],
        },
        isDisabled: (data) =>
          !data?.some((x) => Number(x.label) >= 1 && Number(x.label) <= 3 && x.count > 5),
      },
      {
        value: 'md',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.In,
          field: FilterField.YearsSinceInvestment,
          value: [4, 5, 6, 7],
        },
        isDisabled: (data) =>
          !data?.some((x) => Number(x.label) >= 4 && Number(x.label) <= 6 && x.count > 5),
      },
      {
        value: 'lg',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.Gte,
          field: FilterField.YearsSinceInvestment,
          value: 8,
        },
        isDisabled: (data) => !data?.some((x) => Number(x.label) >= 8 && x.count > 5),
      },
    ],
  },
  {
    field: FilterField.BusinessStage,
    localePrefix: 'stageOfBusiness',
    indicatorName: 'stageOfBusiness',
    filters: [
      { value: 'Seed/startup stage' },
      { value: 'Venture stage' },
      { value: 'Growth stage' },
      { value: 'Mature, private companies' },
      { value: 'Mature, publicly traded companies' },
      { value: 'Not applicable' },
    ],
  },
  {
    field: FilterField.IncomeGroup,
    localePrefix: 'incomeGroup',
    indicatorName: 'incomeGroup',
    filters: [
      { value: 'High income' },
      { value: 'Upper middle income' },
      { value: 'Lower middle income' },
      { value: 'Low income' },
    ],
  },
  {
    field: FilterField.Region,
    localePrefix: 'region',
    indicatorName: 'primaryCountryByRegion',
    filters: [
      { value: 'Eastern Asia' },
      { value: 'Europe' },
      { value: 'Latin America and Caribbean' },
      { value: 'Northern Africa' },
      { value: 'Northern America' },
      { value: 'Oceania' },
      { value: 'South-eastern Asia' },
      { value: 'Southern Asia' },
      { value: 'Sub-Saharan Africa' },
      { value: 'Western Asia' },
    ],
  },
  {
    field: FilterField.ImpactStrategy,
    localePrefix: 'investeePrimaryStrategy',
    indicatorName: 'impactStrategy',
    filters: [
      { value: 'Access to carbon markets' },
      { value: 'Timberland harvest' },
      { value: 'Non-timber forest products' },
      { value: 'Conservation' },
    ],
  },
  {
    field: FilterField.ForestType,
    localePrefix: 'forestType',
    indicatorName: 'forestType',
    filters: [{ value: 'Natural' }, { value: 'Plantation' }],
  },
  {
    field: FilterField.EcoRegion,
    localePrefix: 'ecoregion',
    indicatorName: 'ecoRegion',
    filters: [{ value: 'Tropical' }, { value: 'Temperate' }, { value: 'Taiga (Boreal Forests)' }],
  },
];
