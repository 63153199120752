import typography from '@tailwindcss/typography';
import { type Config } from 'tailwindcss';
import defaultConfig from 'tailwindcss/defaultConfig';


export default {
  content: ['./components/**/*.{css,jsx,ts,tsx}'],
  theme: {
    colors: {
      // Base colors
      white: '#fff',
      transparent: 'transparent',
      gray: {
        900: '#737677',
        500: '#ABADAD',
        400: '#B9BBBB',
        300: '#E3E4E4',
        200: '#F1F1F1',
        50: '#FBFBFB',
      },

      // Primary/Branding colors
      midnight: '#041E42',
      barbie: '#EC008C',
      marigold: '#FEB800',

      // Secondary colors
      coral: {
        DEFAULT: '#F56160',
        50: '#FEEFEF',
      },
      plum: '#461973',
      skyblue: '#03D7DE',
      green: '#04D939',
      red: '#DE2D26',
      ocean: '#2271B5',

      // SDG colors
      sdg: {
        1: '#C92139',
        2: '#DEA63C',
        3: '#519E46',
        4: '#C51A2D',
        5: '#F06A2F',
        6: '#2DBDE1',
        7: '#DEA63C',
        8: '#A22145',
        9: '#F06A2F',
        10: '#DD1C6A',
        11: '#F1A145',
        12: '#B68D42',
        13: '#437D47',
        14: '#2897D1',
        15: '#519E46',
        16: '#07699D',
        17: '#1D496A',
      },
    },
    extend: {
      fontFamily: {
        // @ts-expect-error improper type definition
        sans: ['soleil', ...(defaultConfig.theme?.fontFamily?.sans ?? [])],
      },
      borderWidth: {
        0.5: '0.5px',
      },
      keyframes: {
        fadeIn: {
          '0%': { opacity: '0', transform: 'scale(0.95)' },
          '100%': { opacity: '1', transform: 'scale(1)' },
        },
        slideInRight: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        slideInLeft: {
          '0%': { transform: 'translateX(-100%)' },
          '100%': { transform: 'translateX(0)' },
        },
      },
      animation: {
        fadeIn: 'fadeIn 0.3s ease-out',
        slideInRight: 'slideInRight 0.3s ease-out',
        slideInLeft: 'slideInLeft 0.3s ease-out',
      },
    },
  },
  plugins: [typography],
} satisfies Config;
