import { useMemo } from 'react';

import { FilterCond, FilterField, FilterOp } from '@/generated/graphql';
import { useViewer } from '@/hooks/useViewer';

import { useActiveFilters } from './useActiveFilters';
import { deriveFilterConditions } from '../utils';

interface FilterConditions {
  benchmark: FilterCond[];
  viewer: FilterCond[] | null;
}

export const useFilterConditions = (): FilterConditions => {
  const activeFilters = useActiveFilters();
  const benchmarkFilterConditions = useMemo(
    () => deriveFilterConditions(activeFilters),
    [activeFilters]
  );

  const { viewer } = useViewer();
  const viewerOrg = viewer?.salesforceAccountId ?? viewer?.orgId;

  return {
    benchmark: benchmarkFilterConditions,
    viewer: useMemo(
      () =>
        viewerOrg
          ? [
              ...benchmarkFilterConditions,
              { field: FilterField.Org, op: FilterOp.Eq, value: viewerOrg },
            ]
          : null,
      [benchmarkFilterConditions, viewerOrg]
    ),
  };
};
