import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { MarkdownContent } from 'ui-v2';

import content from './content.md';

export const Route = createFileRoute('/acknowledgements')({
  component: Acknowledgements,
});

function Acknowledgements() {
  return <MarkdownContent content={content} />;
}
