import { useMemo } from 'react';
import { VerticalBarDatasetKey } from 'ui-v2/components/VerticalBar/VerticalBarContext';
import { colors } from 'ui-v2/theme';
import { isNaValue } from 'utils';
import { getDisplayValue } from '../-helpers/getDisplayValue';
import { getYAxisProps } from '../-helpers/getYAxisProps';

interface UseChartDataProps {
  viewerData?: { average?: number | null };
  benchmarkData?: { average?: number | null };
  referenceLineValues?: number[];
}

export const useChartData = ({
  viewerData,
  benchmarkData,
  referenceLineValues,
}: UseChartDataProps) => {
  const groups = [{ key: 'investment', label: '' }];

  const data = useMemo(() => {
    const isNaViewerValue = isNaValue(viewerData?.average);
    const isNaBenchmarkValue = isNaValue(benchmarkData?.average);

    const unsortedData = [
      {
        key: VerticalBarDatasetKey.investment,
        label: isNaViewerValue ? 'Submit data and compare' : 'Your investment',
        color: isNaViewerValue ? colors.barbie : colors.green,
        isDashed: isNaViewerValue,
        groups: {
          investment: {
            value: isNaViewerValue ? 10 : Number(viewerData?.average) * 100,
            displayValue: isNaViewerValue ? 'N/A' : getDisplayValue(viewerData?.average),
          },
        },
      },
      {
        key: VerticalBarDatasetKey.benchmark,
        label: 'Benchmark',
        color: isNaBenchmarkValue ? colors.barbie : colors.skyblue,
        isDashed: isNaBenchmarkValue,
        groups: {
          investment: {
            value: isNaBenchmarkValue ? 10 : Number(benchmarkData?.average) * 100,
            displayValue: isNaBenchmarkValue ? 'N/A' : getDisplayValue(benchmarkData?.average),
          },
        },
      },
    ];

    return unsortedData;
  }, [viewerData, benchmarkData]);

  const yAxisProps = useMemo(() => {
    const values = [benchmarkData?.average, viewerData?.average]
      .filter((x): x is number => typeof x === 'number')
      .map((x) => x * 100);

    return getYAxisProps([...values, ...(referenceLineValues || [])]);
  }, [benchmarkData?.average, viewerData?.average]);

  return { groups, data, yAxisProps };
};
