export const storage = {
  local: {
    get(key: string, defaultValue?: any) {
      try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : defaultValue;
      } catch {
        return defaultValue ?? false;
      }
    },
    set(key: string, value: any) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
      } catch {
        return false;
      }
    },
    remove(key: string) {
      try {
        localStorage.removeItem(key);
        return true;
      } catch {
        return false;
      }
    },
  },
  session: {
    get(key: string, defaultValue?: any) {
      try {
        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : defaultValue;
      } catch {
        return defaultValue ?? false;
      }
    },
    set(key: string, value: any) {
      try {
        sessionStorage.setItem(key, JSON.stringify(value));
        return true;
      } catch {
        return false;
      }
    },
    remove(key: string) {
      try {
        sessionStorage.removeItem(key);
        return true;
      } catch {
        return false;
      }
    },
  },
};
