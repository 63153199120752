import React, { useEffect } from 'react';
import {
  ChartCard,
  HorizontalBars,
  InfoTooltip,
  type RelativePerformance,
  getRelativePerformance,
} from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import { useSortedChartData } from '../-hooks/useSortedChartData';
import { OptionalKey } from '../route';

export const KPIImpact_LandManagedFragment = gql(/* GraphQL */ `
  fragment KPIImpact_LandManagedFragment on ForestryIndicators {
    landManaged {
      trimmedAvg
      isDataSuppressed
    }
    landManaged_n {
      trimmedAvg
      isDataSuppressed
    }
    landManaged_pace {
      trimmedAvg
      isDataSuppressed
    }
  }
`);

type LandManagedProps = {
  className?: string;
  indicators: FragmentType<typeof KPIImpact_LandManagedFragment>;
  viewerIndicators?: FragmentType<typeof KPIImpact_LandManagedFragment>;
  investmentLevel: OptionalKey;
  onEvaluatePerformance: (key: string, performance: RelativePerformance) => void;
};

export function LandManaged({
  indicators,
  viewerIndicators,
  investmentLevel,
  className,
  onEvaluatePerformance,
}: LandManagedProps) {
  const benchmarkData = useFragment(KPIImpact_LandManagedFragment, indicators);
  const viewerData = useFragment(KPIImpact_LandManagedFragment, viewerIndicators);

  const benchmarkDataMap = {
    company: benchmarkData.landManaged,
    investment: benchmarkData.landManaged_n,
    rate_of_change: benchmarkData.landManaged_pace,
  };

  const viewerDataMap = {
    company: viewerData?.landManaged,
    investment: viewerData?.landManaged_n,
    rate_of_change: viewerData?.landManaged_pace,
  };

  const selectedBenchmarkData = benchmarkDataMap[investmentLevel];
  const selectedViewerData = viewerDataMap[investmentLevel];

  const { t, title, titleTooltipKPI } = useChartTranslations('landManaged');

  const getDisplayValue = (value?: number | null): string => {
    if (value == null) return '';
    const strValue = value.toLocaleString('en', { maximumFractionDigits: 0 });
    return t('displayValue', { value: strValue }) ?? strValue;
  };

  const { sortedChartData } = useSortedChartData({
    selectedBenchmarkData,
    selectedViewerData,
    getDisplayValue,
  });

  const relativePerformance = getRelativePerformance(
    selectedBenchmarkData?.trimmedAvg,
    selectedViewerData?.trimmedAvg
  );

  useEffect(() => {
    onEvaluatePerformance('landManaged', relativePerformance);
  }, [onEvaluatePerformance, relativePerformance]);

  return (
    <ChartCard className={className}>
      <ChartCard.Header className="flex items-center justify-between">
        <ChartCard.Title>
          {title}
          {titleTooltipKPI && <InfoTooltip className="ml-1">{titleTooltipKPI}</InfoTooltip>}
        </ChartCard.Title>
        {/* <Button variant="outline" className="shrink-0 font-semibold uppercase">
          view details
        </Button> */}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={selectedBenchmarkData.isDataSuppressed}>
        <HorizontalBars
          min={Math.min(
            0,
            selectedViewerData?.trimmedAvg ?? 0,
            selectedBenchmarkData.trimmedAvg ?? 0
          )}
          max={Math.max(
            100,
            selectedViewerData?.trimmedAvg ?? 0,
            selectedBenchmarkData.trimmedAvg ?? 0
          )}
          showInlineDisplayValue={true}
        >
          {sortedChartData.map((data, index) => (
            <HorizontalBars.Item
              key={index}
              label={data.label}
              value={data.value}
              displayValue={data.displayValue}
              color={data.color}
              isDashed={data.isDashed}
            />
          ))}
        </HorizontalBars>

        {/* <Link href="/impact-kpis/land-managed" className="mt-5 lg:hidden">
          <Button variant="outline" size="small" className="shrink-0 font-semibold uppercase">
            view details
          </Button>
        </Link> */}
      </ChartCard.Body>
    </ChartCard>
  );
}
