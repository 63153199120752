import { useMemo } from 'react';
import { isNaValue } from 'utils';
import { colors } from 'ui-v2/theme';

interface UseSortedChartDataProps {
  selectedViewerData?: { trimmedAvg?: number | null };
  selectedBenchmarkData: { trimmedAvg?: number | null };
  getDisplayValue: (value?: number | null) => string;
}

export const useSortedChartData = ({
  selectedViewerData,
  selectedBenchmarkData,
  getDisplayValue,
}: UseSortedChartDataProps) => {
  return useMemo(() => {
    const isNaViewerValue = isNaValue(selectedViewerData?.trimmedAvg);
    const isNaBenchmarkValue = isNaValue(selectedBenchmarkData?.trimmedAvg);
    const chartData = [
      isNaViewerValue
        ? {
            label: 'Submit data and compare',
            value: 0,
            displayValue: 'N/A',
            color: colors.barbie,
            isDashed: true,
          }
        : {
            label: 'Your average investment',
            value: Number(selectedViewerData?.trimmedAvg),
            displayValue: getDisplayValue(selectedViewerData?.trimmedAvg),
            color: colors.green,
          },
      {
        label: 'Benchmark average',
        value: Number(selectedBenchmarkData.trimmedAvg),
        isDashed: isNaBenchmarkValue,
        displayValue: isNaBenchmarkValue
          ? 'N/A'
          : getDisplayValue(selectedBenchmarkData.trimmedAvg),
        color: isNaBenchmarkValue ? colors.barbie : colors.skyblue,
      },
    ];

    return { sortedChartData: chartData };
  }, [selectedViewerData, selectedBenchmarkData]);
};
