import { colors } from '../theme';

// Define the color groups
const defaultColorGroups: string[][] = [
  // Primary:
  [
    colors.midnight,
    colors.barbie,
    colors.marigold,
    colors.plum,
    colors.skyblue,
    colors.green,
    colors.red,
    colors.ocean,
  ],

  // When needed:
  ['#F2416F', '#F66160', '#F98151', '#FCA243'],
];

// Main function of the color generation
export const generateChartColors = (
  desiredColors: number,
  colors: string[][] = defaultColorGroups
): string[] => {
  if (!desiredColors) return [];

  // Merge groups together keeping priority
  const allColors = colors.flatMap((group) => group);

  // Return the colors if there are enough
  if (desiredColors <= allColors.length) {
    return allColors.slice(0, desiredColors);
  }

  // Recycle colors until we have enough
  const additionalColors = generateChartColors(desiredColors - allColors.length, colors);
  return [...allColors, ...additionalColors];
};
