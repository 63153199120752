import { faCircleInfo } from '@fa-icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

import { Tooltip } from '../Tooltip';
import { cn } from 'utils';

type InfoTooltipProps = PropsWithChildren<{ className?: string }>;

export function InfoTooltip({ className = '', children }: InfoTooltipProps) {
  return (
    <Tooltip variant="tooltip">
      <Tooltip.Trigger>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className={cn('shrink-0 text-base text-gray-500', className)}
          size="1x"
        />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <div className="max-w-52 text-gray-900 md:max-w-xs">{children}</div>
        <Tooltip.Arrow />
      </Tooltip.Content>
    </Tooltip>
  );
}
