import { isNaValue } from 'utils';

const TOLERANCE = 0.1;

export type RelativePerformance = 'over' | 'under' | 'within' | null;

export function getRelativePerformance(
  benchmark?: number | null,
  viewer?: number | null
): RelativePerformance {
  if (isNaValue(benchmark) || isNaValue(viewer)) return null;
  if (viewer > benchmark * (1 + TOLERANCE)) return 'over';
  if (viewer < benchmark * (1 - TOLERANCE)) return 'under';
  return 'within';
}
