import React, { useMemo } from 'react';
import { ChartCard, generateChartColors, InfoTooltip, PieChart } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import orderBy from 'lodash/orderBy';

export const Index_StageOfBusinessFragment = gql(/* GraphQL */ `
  fragment Index_StageOfBusinessFragment on ForestryIndicators {
    stageOfBusiness {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type StageOfBusinessProps = {
  className?: string;
  indicators: FragmentType<typeof Index_StageOfBusinessFragment>;
};

export function StageOfBusiness({ indicators, className }: StageOfBusinessProps) {
  const { stageOfBusiness } = useFragment(Index_StageOfBusinessFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip, getOptionDisplayValue } =
    useChartTranslations('stageOfBusiness');

  const pieData = useMemo(() => {
    const colors = generateChartColors(stageOfBusiness.items.length);
    const sorted = orderBy(
      stageOfBusiness.items
        .filter((item) => !!item)
        .map((item, index) => ({
          label: getOptionLabel(item?.label),
          tooltipLabel: getOptionTooltip(item?.label),
          value: item?.pct,
          color: colors[index],
          displayValue: getOptionDisplayValue(item.count, {
            pct: item.pct.toLocaleString('en', { maximumFractionDigits: 1 }),
          }),
        })),
      ['value'],
      ['desc']
    );
    return sorted.map((x, i) => ({ ...x, color: colors[i] }));
  }, [stageOfBusiness.items]);

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={stageOfBusiness.isDataSuppressed}>
        <PieChart data={pieData} showLegend chartPosition="left" />
      </ChartCard.Body>
    </ChartCard>
  );
}
