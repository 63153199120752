import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { MarkdownContent } from 'ui-v2';

import content from './content.md';

export const Route = createFileRoute('/methodologies')({
  component: Methodologies,
});

function Methodologies() {
  return <MarkdownContent content={content} />;
}
