import React, { ComponentProps } from 'react';
import { cn } from 'utils';

import { BorderBox } from '../BorderBox';
import { SectionTitle } from '../SectionTitle';
import { ChartCardBody } from './ChartCardBody';
import { ChartCardHeader } from './ChartCardHeader';
import { ChartCardSubtitle } from './ChartCardSubtitle';

export function ChartCard({ className, children, ...props }: ComponentProps<typeof BorderBox>) {
  return (
    <BorderBox className={cn('flex flex-col gap-4 sm:p-6', className)} {...props}>
      {children}
    </BorderBox>
  );
}

ChartCard.Title = ({ className, children, ...props }: ComponentProps<typeof SectionTitle>) => (
  <SectionTitle className={cn('flex items-baseline gap-2', className)} {...props}>
    {children}
  </SectionTitle>
);

ChartCard.Header = ChartCardHeader;
// ChartCard.Title = SectionTitle;
ChartCard.Subtitle = ChartCardSubtitle;
ChartCard.Body = ChartCardBody;
