import React, { FC } from 'react';
import { cn } from 'utils';
import { ChartLegendItem } from './ChartLegendItem';

interface ChartLegendProps {
  data: { color: string; label: string; isDashed?: boolean }[];
  layout?: 'vertical' | 'horizontal';
  className?: string;
}

type ComposedChartLegend = FC<ChartLegendProps> & {
  Item: typeof ChartLegendItem;
};

export const ChartLegend: ComposedChartLegend = ({ data, layout = 'vertical', className }) => (
  <ul
    className={cn(
      'flex gap-4',
      {
        'flex-col': layout === 'vertical',
        'flex-row': layout === 'horizontal',
      },
      className
    )}
  >
    {data.map((item) => (
      <ChartLegendItem key={item.label} item={item} />
    ))}
  </ul>
);

ChartLegend.Item = ChartLegendItem;
