import { createFileRoute } from '@tanstack/react-router';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderBox, InfoTooltip, SectionTitle, Select, type RelativePerformance } from 'ui-v2';

import { useIsValidFilters } from '@/hooks/useIsValidFilters';
import { InvalidFilters } from '@/components/InvalidFilters';

import { DecentJobs } from './-components/DecentJobs';
import { Emissions1And2Removed } from './-components/Emissions1And2Removed';
import { EmissionsRemoved } from './-components/EmissionsRemoved';
import { ForestBeneficiaries } from './-components/ForestBeneficiaries';
import { InvesteeRevenueGrowth } from './-components/InvesteeRevenueGrowth';
import { LandManaged } from './-components/LandManaged';
import { LandProtected } from './-components/LandProtected';
import { LandRestored } from './-components/LandRestored';
import { useImpactKpisData } from './-hooks/useImpactKpisData';

export type OptionalKey = 'company' | 'investment' | 'rate_of_change';

const options: { value: OptionalKey; label: string }[] = [
  { value: 'company', label: 'Company' },
  { value: 'investment', label: 'Investment' },
  { value: 'rate_of_change', label: 'Rate of change' },
];

export const Route = createFileRoute('/impact-kpis')({
  component: Impact,
});

function Impact() {
  const { t } = useTranslation();

  const { loading, isValid } = useIsValidFilters();
  const { benchmarkData, viewerData } = useImpactKpisData({ skip: !isValid || loading });

  const [investmentLevel, setInvestmentLevel] = useState<OptionalKey>(options[0].value);

  const [performanceRecord, setPerformanceRecord] = useState<Record<string, RelativePerformance>>(
    {}
  );

  const onEvaluatePerformance = useCallback(
    (key: string, value: RelativePerformance) =>
      setPerformanceRecord((prev) => ({ ...prev, [key]: value })),
    []
  );

  console.log(performanceRecord);

  const performanceNode = useMemo(() => {
    const relativePerformance = Object.entries(performanceRecord).reduce(
      (acc, [key, value]: [key: string, value: RelativePerformance]) => {
        if (!value) return acc;
        if (value === 'over') acc.overperforming.push(key);
        if (value === 'within') acc.withinBenchmark.push(key);
        if (value === 'under') acc.underperforming.push(key);
        return acc;
      },
      {
        overperforming: [] as string[],
        withinBenchmark: [] as string[],
        underperforming: [] as string[],
      }
    );

    if (!Object.values(relativePerformance).some((x) => x.length > 0)) return;

    const overperformingNode = relativePerformance?.overperforming.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_overperforming')}</strong> at{' '}
        {relativePerformance.overperforming.length} investments
      </>
    ) : null;

    const performingWithinNode = relativePerformance?.withinBenchmark.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_performingWithin')}</strong> at{' '}
        {relativePerformance.withinBenchmark.length} investments
      </>
    ) : null;

    const underperformingNode = relativePerformance?.underperforming.length ? (
      <>
        <strong>{t('impactKPIPage_subtitle_underperforming')}</strong> at{' '}
        {relativePerformance.underperforming.length} investments
      </>
    ) : null;

    const nodes = [overperformingNode, performingWithinNode, underperformingNode].filter(Boolean);
    if (nodes.length === 0) return null;

    return (
      <p className="text-gray-900">
        {t('impactKPIPage_subtitle_leadIn')}
        {nodes.map((node, i) => {
          if (i === 0) return <span key={i}> {node}</span>;
          if (i === nodes.length - 1)
            return (
              <span key={i}>
                {' '}
                <br />
                and {node}
              </span>
            );
          return (
            <span key={i}>
              , <br />
              {node}
            </span>
          );
        })}
      </p>
    );
  }, [performanceRecord]);

  if (loading) return null;
  if (!isValid) return <InvalidFilters />;
  if (!benchmarkData) return null;

  const chartProps = {
    indicators: benchmarkData,
    viewerIndicators: viewerData ?? undefined,
    investmentLevel,
    onEvaluatePerformance,
  };

  const titleTooltip = t('impactKPIPage_titleTooltip');
  const levelTooltip = t('impactKPIPage_levelTooltip');

  return (
    <BorderBox className="relative">
      {/* header */}
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex flex-wrap items-baseline gap-x-3 gap-y-2">
          <div className="flex items-center gap-1.5">
            <SectionTitle>{t('impactKPIPage_title')}</SectionTitle>
            {titleTooltip && <InfoTooltip>{titleTooltip}</InfoTooltip>}
          </div>
          {performanceNode}
        </div>
        <div className="flex items-center gap-1.5">
          <Select
            options={options}
            title="Impact Level"
            value={investmentLevel}
            onChange={setInvestmentLevel}
          />
          <InfoTooltip>{levelTooltip}</InfoTooltip>
        </div>
      </div>
      {/* body */}
      <div className="grid grid-cols-1 gap-6 pt-9 md:grid-cols-2">
        <LandManaged {...chartProps} />
        <LandProtected {...chartProps} />
        <LandRestored {...chartProps} />
        <Emissions1And2Removed {...chartProps} />
        <EmissionsRemoved {...chartProps} />
        <ForestBeneficiaries {...chartProps} />
        <DecentJobs {...chartProps} />
        <InvesteeRevenueGrowth {...chartProps} />
      </div>
    </BorderBox>
  );
}
